import * as powerbiModels from 'powerbi-models';
import { environment } from 'src/environments/environment';
import { EveryBuddyConstants } from './models/everybuddy-constants';

export const EveryBuddy: EveryBuddyConstants = {
  /** Overridden by AuthService. */
  CurrentOrganisationId: 0,
  IsAngular12: true,
  ServerId: environment.serverId,
  WebAPI: environment.oldApiUrl,
  CoreAPI: environment.apiUrl,
  IdUrl: environment.idUrl,
  BlobBase: environment.blobBase,
  CdnBase: environment.cdnBase,
  /** Replaced during build */
  Version: "24808",
  LogosPath: "logos/",
  PaginationMaxNumberOfPages: "15",
  UploadFileSizeLimitMB: "10",
  DisplayInUserLanguage: true,
  /** Overridden by OrganisationService */
  CurrentOrganisationTimeZone: '',
  /** Overridden by Ng1StateDefinitionService */
  ng1Routes: [],
  Constants: {
    /** Overridden by OrganisationService */
    CurrencyExponent: 2,
    /** Overridden by OrganisationService */
    CurrencyDisplaySymbol: '',
  },
  Enums: {
    PersonDataBlocks: {
      PersonData: 1,
      ThirdPartyIds: 2,
      UsageData: 4,
      RoleData: 8,
      LinkedPeople: 16,
      PupilInfo: 32,
      Forms: 64,
      Membership: 128
    },
    OrganisationTypes: {
      None: 0,
      OriginalSubsbuddy: 1,
      School: 2,
      Club: 3,
      Finance: 4,
      Faith: 5
    },
    OrganisationSchoolFieldVisibility: {
      None: 0,
      ShowYears: 1,
      ShowHouses: 2,
      ShowClasses: 4
    },
    WelcomeMessageAudience: {
      Parents: 0,
      Pupils: 1,
      Staff: 2
    },
    RoleTypes: {
      Associated: 0,
      Admin: 1,
      Player: 2,
      Captain: 3,
      ViceCaptain: 4,
      Manager: 5,
      Coach: 6,
      TeamAssistant: 7,
      President: 8,
      Chairman: 9,
      Secretary: 10,
      Treasurer: 11,
      CommitteeMember: 12,
      Director: 13,
      NonPlayer: 14,
      Member: 15,
      Supporter: 16,
      Umpire: 17,
      Referee: 18,
      Linesman: 19,
      TouchJudge: 20,
      Driver: 21,
      Refreshments: 22,
      Teacher: 23,
      HeadOfActivity: 24,
      HeadOfYear: 25,
      Pupil: 26,
      AbsenteeAlerts: 27,
      SystemAdmin: 28,
      Historical: 29,
      GroupCallImport: 30,
      Finance: 31,
      CCAAdmin: 32,
      NewRegistrations: 33,
      Membership: 34,
      OrganisationSuperAdmin: 35,
      CommunicationAdmin: 36,
      AdvancedFeeAdmin: 37,
      TransportAdmin: 38,
      TransportAdvancedAdmin: 39,
      TransportAlerts: 40,
      ExternalCoachAccess: 100,
      TransportBusMonitor: 101
    },
    MemberType: {
      All: 0,
      Pupils: 1,
      Parents: 2,
      Staff: 3
    },
    PaymentProvider: {
      None: 0,
      Unpaid: 1,
      CashChq: 2,
      FOC: 3,
      DebitBalance: 4,
      PayPal: 5,
      LinkedPayment: 6,
      Stripe: 7,
      Cheque: 13,
      PaySafe: 15,
      PayFort: 17,
      CheckOut: 18,
      AsiaPay: 19,
      Voucher: 20,
      CyberSource: 21,
      Credit: 22,
      Refund: 23,
      InternalTransfer: 24,
      LegacyVoucher: 30,
      LegacyRefund: 31,
      LegacyTransfer: 32,
      BankTransfer: 50,
      BrainTree: 51,
      BlueSnap: 52,
      RedDot: 53
    },
    CaseServices: {
      None: 0,
      Mortgage: 1,
      Insurance: 2,
      Investment: 4
    },
    CalendarEventViewAttendeeStatus: {
      None: 0,
      Selected: 1,
      Notified: 2,
      Attending: 3,
      HelpRequested: 4,
      AttendanceRequested: 5,
      AvailabilityRequested: 6,
      AvailableAwaitingSelection: 7,
      PendingSelection: 8,
      Invited: 9,
      InviteesOnly: -3,
      NotSelected: -2,
      Declined: -1
    },
    CalendarEventViewTrafficLight: {
      Wait: 0,
      Go: 1,
      WaitingOnOrganisating: 2,
      Stop: 3
    },
    GroupDetailEditorDisplayMode: {
      School: 0,
      "Status365": 1
    },
    PreferenceSelectionScope: {
      Daily: 0,
      Weekly: 1
    }
  }
}

export const PowerbiClient = {
  models: powerbiModels
}
